<template>
	<div class="wrapper_main">
		<main class="content">
			<div class="content__header">
				<div class="content__header-left">
					<h1 class="heading-page-h1">{{ $translate('device-groups') }}</h1>
				</div>
				<BluePlusButton to="/device-groups/add">{{ $translate('add-group') }}</BluePlusButton>
			</div>



			<TableAdvanced v-model="sortCol" :rows="filteredRows" :columns="columns"  :isCheckedAll="isCheckedAll" :checkedInputs="checkedInputs" @toggleAllCheckedInputs="toggleAllCheckedInputs">
                <template v-slot:filters>
					<div class="filter-options__items flex-auto">
						<div class="filter-options__search">
							<input type="search" class="input bg-left search-icon" placeholder="Search" v-model="rowsFilters.search" />
						</div>
					</div>
                </template>

                <template v-slot:row="slotData">
					<td>
						<label class="module__check">
                            <input type="checkbox" name="group" :checked="checkedInputs[slotData.r.id]" @change="setCheckedInputs($event,slotData.r.id)"/>
							<span class="check"></span>
							<router-link :to="`/device-groups/${slotData.r.id}`">
								<span class="text has-icon">
									<inline-svg :src="require(`@/assets/img/Blank.svg`)" />
									{{ slotData.r.name || $translate('none') }}
								</span>
							</router-link>
						</label>
					</td>
					<td style="text-align: center">
						{{ slotData.r.IOTDevices }}
					</td>
					<td>
						<div class="table-actions flex items-center justify-center">
							<!-- <router-link class="btn btn-transp" :to="'/device-groups/'+slotData.r.id">
								<inline-svg :src="require(`@/assets/img/ico-settings.svg`)" />
							</router-link> -->
							
							<div class="table-options-drop dropdown-wrapper" >
								<button
									type="button" class="btn btn-sm btn-transp dropdown-btn" 
									:class="{'active':menuOpen.open === slotData.r.id }"
									@click="openOptions($event, slotData.r.id)"
								>
									<inline-svg :src="require(`@/assets/img/ico-settings.svg`)" />

								</button>
								<div  
									class="categories__dropdown dropdown-content" 
									:class="{'active':menuOpen.open === slotData.r.id }"
									style="position: fixed;"
									:style="{
										left: menuOpen.left,
										top: menuOpen.top,
										bottom: 'auto'
									}"
								>
									<ul>
										<li>
											<a @click="renameGroupOpen(slotData.r.id,slotData.r.name)">{{ $translate('rename-group') }}</a>
										</li>
										<li>
											<router-link :to="`/device-groups/${slotData.r.id}`">{{ $translate('edit-group') }}</router-link>
										</li>
										<li>
											<router-link :to="`/reports`">{{ $translate('report') }}</router-link>
										</li>
										
									</ul>
								</div>
							</div>

							
							<button class="btn btn-transp red-color" @click="confirmDelete(slotData.r.id, $translate('delete-group?') )">
								<inline-svg :src="require(`@/assets/img/trash-delete.svg`)" />
							</button>
						</div>
					</td>
                </template>
            </TableAdvanced>
			<!-- :searchTitle="$translate('no-products-corresponding-to-the-criteria')" -->
			<TableMessages
                :rows="rows"
                :filters="rowsFilters"
                :title="$translate('add-a-new-group')"               
                searchTitle="No Items Corresponding To Your Criteria"
				:addTitle="$translate('add-group')"
                to="/device-groups/add"
            ></TableMessages>
			<!-- before translate  title="Add a new group" searchTitle="No Products Corresponding To The Criteria" addTitle="Add Group"-->
		</main>
		<Pagination v-model="filteredRows" :items="rawSource" />
		<RenamePopup
			v-if="renamePopupInfo.show"
			
			:currentName="renamePopupInfo.currentName"
			@close="renameGroupClose"
			@submit="renameGroupSubmit"
			:processing="renamePopupInfo.processing"
			
		></RenamePopup>
	</div>
</template>

<script>
import { API } from 'aws-amplify';

import {
	updateDeviceGroup
} from '@/graphql/mutations'
import { mixTables,mixDeleteItem } from "@/mixins";
import Popups from "@/components/popups";
import Base from "@/components/base";
import Buttons from "@/components/buttons";
export default {
	name: "DevicesGroups",
	mixins:[mixTables,mixDeleteItem],
	components: {
        ...Popups,
        ...Base,
        ...Buttons
    },
	data() {
		return {
			source: "groups",
			columns: [
                { name: "Name", filter: "name", class:"w-100" },
                { name: "IOT Devices", filter: "IOTDevices"},
				{ name: "Actions", class:"text-end"}
            ],
			calculatedFields:[{
				filter: 'IOTDevices',
				path: ['devices','items'],
				calcFunction: (obj)=>obj.length
			}], 

			deleteQueryType:"deleteDeviceGroup",
			renamePopupInfo:{
				show:false,
				id:null,
				currentName:null,
				processing:false,
			},
			menuOpen: {
                left: '-500px',
                top: 0,
                open: null,
            },
			
		};
	},
	
	computed: {
		companyData() {
			return this.$store.state.companyData
		},
		deviceGroups() {
			return this.$store.state.groups || []
		},
		devices(){
			return this.$store.state.devices
		}
	},
	async created() {

	},
	methods: {
		openOptions(ev, id) {
            if(this.menuOpen.open === id){
                this.menuOpen.open = null;
                this.menuOpen = {
                    left: '-500px',
                    top: 0,
                    open: null,
                }
            } else {
                this.menuOpen.open = id;
                setTimeout(() => {
                    let targetClientRect = ev.target.getBoundingClientRect();

                    this.menuOpen.left = (targetClientRect.x - ev.target.nextElementSibling.offsetWidth + 22) + 'px';

                    if((targetClientRect.top + targetClientRect.height + ev.target.nextElementSibling.offsetHeight+70) > document.querySelector('#app').offsetHeight){
                        this.menuOpen.top = ((targetClientRect.y - ev.target.nextElementSibling.offsetHeight) - 10) + 'px';
                    } else {
                        this.menuOpen.top = (targetClientRect.y + 15) + 'px';
                    }
                }, 10);
            }

            // ev.target.classList.toggle("active");
            // ev.target.nextElementSibling.classList.toggle("active");
        },
        outsideMenuClick(e){
            if(this.menuOpen.open&&!e.target.closest('.table-options-drop.dropdown-wrapper')){
                this.menuOpen.open = null;
            }
        },
	
		renameGroupOpen(id,name){
			this.renamePopupInfo.show=true
			this.renamePopupInfo.id=id
			this.renamePopupInfo.currentName=name
		},
		renameGroupClose(){
			this.renamePopupInfo.show=false
			this.renamePopupInfo.id=null
			this.renamePopupInfo.currentName=null
			this.renamePopupInfo.processing=false

		},
		async renameGroupSubmit(name){
			this.renamePopupInfo.processing=true
			const group = await API.graphql({
                query: updateDeviceGroup,
                variables: {
                    input: {
                        id: this.renamePopupInfo.id,
                        name:name
                    },
                },
            }).then((res) => res.data.updateDeviceGroup);
            this.$store.commit("UPDATE_ITEM", { field: "groups", item: group });
			this.renameGroupClose()
		}
	},
	created() {
        window.addEventListener('click', this.outsideMenuClick);
    },
  
    beforeDestroy(){
        window.removeEventListener('click', this.outsideMenuClick);
    }
};
</script>
